import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Select,
  Typography,
  Divider,
} from "antd";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { logoutUser } from "../helpers/authHelper";
import { baseAPIUrl } from "../constants/urlConstants";
import { LotCreator } from "./LotCreator";

const { Option } = Select;
const { Title } = Typography;

export const SourceMedCreator = (props) => {
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [submitting, setSubmitting] = useState(false);
  const [vendors, setVendors] = useState([]);

  //get products and source meds in order to suggest them
  useEffect(() => {
    //when loading ensure page is set

    //get vendors
    fetch(baseAPIUrl + "vendors", {
      headers: { user_key: state.key },
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setVendors(results);
        },
        (error) => {
          console.log(error);
        }
      );

    dispatch({
      type: ACTIONS.SET_CURRENT_PAGE,
      payload: { page: "createSourceMed" },
    });
  }, []);

  const onVendorChange = (value) => {
    const selectedVendor = vendors.find((vendor) => {
      return vendor.id === value;
    });

    console.log(selectedVendor);
    form.setFieldsValue({
      mel: selectedVendor.license_number,
    });
  };

  const onMaterialChange = (value) => {
    let method = "N/A"
    if(value === "Crude") {
      method = "Ethanol"
    } else if (value === "Trim"){
      method = "Veg. Oil"
    }else if (value === "Distillate" || value === "Hybrid"){
      method = "BHO"
    }
    form.setFieldsValue({
      extraction_method: method,
    });
  };

  //handles sending data from form to DB
  const onSubmitSourceMed = (values) => {
    console.log("submitting source med");
    console.log(values);
    setSubmitting(true);
    fetch(baseAPIUrl + "sourcemeds", {
      method: "POST",
      body: JSON.stringify({
        ...values,
      }),
      headers: {
        "Content-Type": "application/json",
        user_key: state.key,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (results) => {
          setSubmitting(false);
          if (results.success) {
            form.resetFields();
            message.success(results.message);
          } else {
            message.error(results.message);
          }
        },
        (error) => {
          setSubmitting(false);
          message.error("Failed to create the source med");
          console.log(error);
        }
      );
  };

  return (
    <div className="creator-content">
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="horizontal"
        name="source_med_creator"
        className="source_med-creator"
        initialValues={{ remember: true }}
        scrollToFirstError
        form={form}
        onFinish={onSubmitSourceMed}
      >
        <Title level={2}>Create A Source Med</Title>
        <Divider />
        <Form.Item
          label="Medicine Name"
          name="med_name"
          rules={[
            {
              message: "Source Med requires a name!",
              required: true,
            },
          ]}
        >
          <Input maxLength={100} placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Vendor Name"
          name="vendor"
          rules={[
            {
              message: "Source Med requires a vendor!",
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Vendor"
            optionFilterProp="children"
            onChange={onVendorChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {vendors.map((vendor, i) => (
              <Option
                value={vendor.id}
                key={i}
              >{`${vendor.vendor_name}(${vendor.partner_name})`}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Batch ID"
          name="batch_id"
          rules={[
            {
              message: "A batchid must be given to a source med!",
              required: true,
            },
          ]}
        >
          <Input maxLength={100} placeholder="Batch ID" />
        </Form.Item>
        {/* <Form.Item
          label="Package ID"
          name="package_id"
          rules={[
            {
              message: "A batchid must be given to a source med!",
              required: true,
            },
          ]}
        >
          <Input maxLength={100} placeholder="Batch ID" />
        </Form.Item> */}
        <LotCreator batchID={form.getFieldValue("batch_id")} />
        <Form.Item
          label="Received Date"
          name="date_received"
          rules={[
            {
              message: "Must enter an expected completion date",
              required: true,
            },
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            //disable selecting a day before today
          />
        </Form.Item>
        <Form.Item
          label="Manufacturing/Harvest Date"
          name="manufacturing_date"
          rules={[
            {
              message: "Must enter a manufacturing/harvest date",
              required: true,
            },
          ]}
        >
          <DatePicker format="MM/DD/YYYY" />
        </Form.Item>
        <Form.Item
          label="Material"
          name="material"
          rules={[
            {
              message: "Must select a type of material!",
              required: true,
            },
          ]}
        >
          <Select showSearch placeholder="Material" onChange={onMaterialChange}>
            <Option value="Crude">Crude</Option>
            <Option value="Trim">Trim</Option>
            <Option value="Distillate">Distillate</Option>
            <Option value="Hybrid">Hybrid</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Dominance"
          name="dominance"
          rules={[
            {
              message: "Must select a dominance!",
              required: true,
            },
          ]}
        >
          <Select showSearch placeholder="Dominance">
            <Option value="Indica Blend">Indica Blend</Option>
            <Option value="Sativa Blend">Sativa Blend</Option>
            <Option value="Balanced THC:CBD Blend">
              Balanced THC:CBD Blend
            </Option>
            <Option value="Hemp CBD Blend">Hemp CBD Blend</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="MEL number"
          name="mel"
          rules={[
            {
              message: "A MEL # must be given to a source med!",
              required: true,
            },
          ]}
        >
          <Input disabled placeholder="MEL" />
        </Form.Item>
        <Form.Item
          label="Extraction Method"
          name="extraction_method"
          rules={[
            {
              message: "An extraction method must be given to a source med!",
              required: true,
            },
          ]}
        >
          <Input disabled placeholder="Extraction" />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-ticket-button"
            loading={submitting}
          >
            Submit Ticket
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
