import React, { useContext, useState } from "react";
import "./CannabanHeader.css";
import { Context } from "../context/store";
import { Layout, Row, Col, Popover, Button, Typography, message } from "antd";
import { logoutUser } from "../helpers/authHelper";
import ACTIONS from "../context/actions";
import { Link, useSearchParams } from "react-router-dom";
import { isManager } from "../helpers/employeeHelper";
import { baseAPIUrl } from "../constants/urlConstants";

const { Header } = Layout;
const { Title } = Typography;

const CannabanHeader = (props) => {
  const [state, dispatch] = useContext(Context);
  const [invReconciled, setReconciled] = useState(true);

  const onLogout = async () => {
    await logoutUser();
    dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
  };

  const reconcileInv = () => {
    fetch("https://halo-results-monitor.herokuapp.com/reconcile");

    message.info("Performing inventory reconcilation!");
    setReconciled(false);
    setTimeout(() => setReconciled(true), 10000);
  };

  return (
    <Header
      className={state.key ? "Cannban-Header" : "Cannban-Header-login"}
      style={process.env.REACT_APP_BETA === "true" ? { background: "red" } : {}}
    >
      <Row>
        <Col span={8} />
        <Col span={8}>
          <Link to="/overview" /*/static*/>
            <Title
              style={{ color: "white", minWidth: "182px" }}
              onClick={() =>
                dispatch({
                  type: ACTIONS.SET_CURRENT_PAGE,
                  payload: { page: "ediblesOverview" },
                })
              }
            >
              {process.env.REACT_APP_BETA === "true" ? "BETA SITE" : "Cannaban"}
            </Title>
          </Link>
        </Col>
        <Col span={8}>
          {state.username ? (
            <Popover
              placement="bottomRight"
              content={
                <>
                  <div>{"Role: " + state.role}</div>
                  {isManager(state.role) && (
                    <>
                      <Button
                        type="dashed"
                        onClick={() => reconcileInv()}
                        disabled={!invReconciled}
                      >
                        Reconcile Inventories
                      </Button>
                      <br />
                    </>
                  )}
                  <Button danger type="text" onClick={() => onLogout()}>
                    Logout
                  </Button>
                </>
              }
            >
              <h4 style={{ textAlign: "end", color: "white" }}>
                Hello {state.username}!
              </h4>
            </Popover>
          ) : null}
        </Col>
      </Row>
    </Header>
  );
};

export default CannabanHeader;
